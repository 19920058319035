@import "../../colors";

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-logo {
  height: 100px;
  margin-bottom: 30px;
}

.login-form {
  width: 90%;
  max-width: 500px;

  border: 1px solid #dddddd22;
  padding: 15px;
  background-color: #333;
  border-radius: 10px;

  .login-title {
    font-weight: bold;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Poppins";
    font-size: 40px;
  }
}

.form-divider {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  .slot-divider {
    flex: 1;
  }

  .slot-divider-middle {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #dddddd44;
    display: block;
  }
}