@import "../../colors";

.page {
  max-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: start;
  align-items: start;

  .page-content {
    max-height: 100vh;
    flex: 1;
    overflow: auto;
    min-height: 100vh;
  }

  .page-navbar {
    min-height: 100vh;
    min-width: 70px;
    max-width: 70px;

    background-color: #222;
    border-right: 1px solid #dddddd22;

    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;

    transition: 200ms ease-out;

  }

  .top-content {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;

    width: 100%;
  }

  .middle-content {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    flex: 1;

    width: 100%;
  }

  .bottom-content {
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    padding: 8px;
  }

  .page-navbar-item {
    display: block;
    font-size: 25px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    color: $primary;

    height: 80px;

    img {
      max-width: 53px;
    }

    &.game {
      padding: 8px;
    }

    &.active {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: -2px;

        content: "";
        height: 100%;
        width: 2px;
        background-color: $primary;
      }
    }

    &.with-text {
      flex-direction: column;

      .text {
        font-size: 12px;
        margin-top: 8px;
      }
    }

    &.logout {
      border: 2px solid $primary;
      border-radius: 16px;
      color: $primary;
      height: 60px;

      &:hover {
        background-color: $primary;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.clear-search {
  background-color: $primary;
}

.breadcrumbs {
  --bs-breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='grey'/></svg>");
  padding: 10px;
  padding-top: 12px;
  border-bottom: 1px solid #dddddd44;
  background-color: #222;
  font-family: "Poppins";

  font-size: 15px;
  text-decoration: none;

  .breadcrum-link {
    color: $primary;
    text-decoration: none;
    cursor: pointer;
  }


  .breadcrumb {
    margin-bottom: 0px;
  }
}