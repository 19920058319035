.league-endgame-wrapper {
  position: absolute;
  top: 240px;
  left: 229px;
  right: 220px;
  bottom: 106px;
  //background-color: rgba(255,0,0, 0.5);
}

.league-team-stats {
  position: absolute;
  top: 0;
  left: 0;
  width: 740px;
  bottom: 0px;

  //background-color: rgba(0, 255, 0, 0.5);
  
  .team-list-element {
    height: 75px;
    width: 100%;
    --index: 0;
    position: absolute;
    top: calc(var(--index) * 82px);
    left: 0;

    border-bottom: 2px solid #eeeeee88;

    &.void-grubs {
      .label {
        font-size: 25px;
      }
    }

    &.last {
      border: 0px;

      .blue-side, .red-side {
        justify-content: space-around
        ;
      }
    }

    font-family: "Poppins";

    .label {
      --width: 160px;
      width: var(--width);
      height: 100%;

      position: absolute;
      left: calc(50% - calc(var(--width) / 2));

      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 28px;
    }

    .elderdragon {
      width: 90px;
    }

    .dragon {
      width: 74px;
    }

    .ban {
      height: 50px;
      border: 1px solid #eeeeee33;
    }

    .baron {
      width: 70px;
      margin-top: -8px;
    }

    .herald {
      width: 90px;
    }
    .grubs {
      width: 45px;
    }


     .blue-side {
        width: 300px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        //background-color: beige;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 28px;
        font-weight: bold;
     }

     .red-side {
      width: 300px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;

      //background-color: beige;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 28px;
      font-weight: bold;
   }

    //background-color: rgba(255,0,0, 0.5);
  }
}

.player-stats {
  position: absolute;
  top: 0;
  right: 14px;
  width: 700px;
  bottom: 0px;
  //background-color: #ff000055;

  .graph {
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
  }

  .players {
    //background-color: #ff000055;
    position: absolute;

    top: 20px;
    left: 0;
    right: 0;
    bottom: 250px;


    .blue-players {
      //background-color: #eeeeee88;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      width: calc(50% - 8px);

      display: flex;
      align-items: start;
      justify-content: space-around;
      flex-direction: column;

      .true {
        background-color: white;
      }

      .magical {
        background-color: rgb(4,123,158);
      }

      .physical {
        background-color: #1eaad4;
      }

    }
    .red-players {
      //background-color: #eeeeee88;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      width: calc(50% - 8px);

      display: flex;
      align-items: start;
      justify-content: space-around;
      flex-direction: column;

      .true {
        background-color: white;;
      }

      .magical {
        background-color: #931209;
      }

      .physical {
        background-color: #da291c;
      }
    }

    .player {
      --height: 88px;
      height: var(--height);
      width: 100%;
      position: relative;

      .player-img {
        height: calc(var(--height));
        width: calc(var(--height));
      
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #eeeeee33;
      }
      .player-name {
        width: 230px;
        height: 45px;

        position: absolute;
        left: 95px;

        display: flex;
        align-items: center;
        justify-content: start;

        font-size: 24px;
      }

      .dmg {
        position: absolute;
        bottom: 0;
        right: 0;
        
        width: 100px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: end;

        font-size: 20px;
        font-weight: bold;
      }
      
      .dmg-bar {
        position: absolute;
        bottom: 0;
        left: 95px;
        right: 80px;
        height: 40px;

        //background-color: #ff000022;

        display: flex;
        align-items: stretch;
        justify-content: start;

        .bar {
          height: 100%;
        }
      }
    }
  }
}