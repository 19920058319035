.tft-endgame-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right:  0;
  bottom: 0;

  //background-image: url("../../../assets//images/Background_plain.png");
}

.players-wrapper {
  position: absolute;
  top: 50px;
  left: 300px;
  bottom: 50px;
  right: 50px;
  //background-color: #44444444;

  display: flex;


  align-items: start;
  justify-content: space-between;
  flex-direction: column;

  overflow: hidden;

  padding: 10px;

  .player-wrapper {
    width: 100%;
    //background-color: #44444444;
    height: 110px;
    padding: 5px;

    display: flex;
    align-items: center;
    justify-self: start;

    .player-name {
      margin-left: 20px;
      font-size: 30px;
      color: black;

      padding: 15px 40px;
      background-color: #eeeeeeaa;
      flex: 1;

      clip-path: polygon(25px 0%, calc(100% - 25px) 0%, 100% 50%, calc(100% - 25px) 100%, 25px 100%, 0% 50%);
    }

    .player-name {
      max-width: 350px;
      min-width: 350px;
    }

    .traits {
      max-width: 300px;
      min-width: 300px;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      padding-left: 15px;

      .trait {
        border: 1px solid #dddddd44;
        padding: 10px;
        padding-top: 0px;
        padding-bottom: 2px;
        border-radius: 20px;
        margin-bottom: 3px;
        margin-right: 5px;
        font-size: 12px;
  
        .value {
          font-size: 14px;
          font-weight: bold;
          margin-left: 5px;
        }
  
        &.bronze {
          border-color: #CD7F32;
          color: #CD7F32;
        }
  
        &.silber {
          border-color: #C0C0C0;
          color: #C0C0C0;
        }
  
        &.gold {
          border-color: #D4AF37;
          color: #D4AF37;
        }
  
        &.chromatic {
          border-color: #576bce;
          color: #576bce;
        }
  
        .trait-img {
          height: 15px;
          margin-top: -3px;
          margin-right: 3px;
          margin-left: -2px;
        }
      }
    }

    .units {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: start;

      margin-left: 15px;
    }

    .lvl {
      margin-left: 10px;
      .inner {
        font-size: 21px!important;

        .lvl-text {
          font-size: 15px;
          margin-right: 5px;
        }
      }
    }

    .unit-wrapper {
      position: relative;
      height: 100%;

      .items {
        width: calc(100% + 30px);
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: -5px;
        left: -15px;
        padding-right: 10px;

        .item {
          height: 25px;
          margin: 1px;
          border: 1px solid #444444ee;
          filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
          overflow: hidden;
        }
      }

      .stars {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        top: -15px;
        left: 0;
        padding-right: 10px;

        .star {
          height: 30px;
          padding: 2px;
          filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

          &.lvl1 {
            fill: #4AD7E1;
          }
          &.lvl2 {
            fill: #da291c;
          }
          &.lvl3 {
            
            fill: #ffdd00;
          }
        }

        z-index: 100;
      }

    }

    .hexagon {
      aspect-ratio: 1/1.1547005;
      height: 100%;
      background-color: white;
      clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
      padding: 9px;
      box-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));


      &.place1 {
        background-color: #ffdd00;

        .inner  {
          padding-left: 5px;
        }
      }

      &.place2 {
        background-color: #C0C0C0;

        .inner  {
          padding-left: 5px;
        }
      }

      &.place3 {
        background-color: #CD7F32;

        .inner  {
          padding-left: 5px;
        }
      }

      &.unit {
        width: 80px;
        margin-right: 10px;
        padding: 6px;

        position: relative;
      }

      &.inner {
        background-color: #22222233;
        
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
        font-weight: normal;
        font-family: "TheBoldFont";
        color: black;
        margin-left: -1px;

        &.unit-inner {
          background-size: cover;
        }
      }
    }
  }
}