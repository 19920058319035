@import "../../colors";

.games {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #dddddd44;
}

.games-description {
  color: yellow;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.position {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #dddddd44;
}

.games-sub {
  font-size: 15px;
  font-weight: normal;
  margin-left: 10px;
}

.league {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0px;

  &.emerald {
    color: #149c3a;
  }

  &.diamond {
    color: #576bce;
  }

  &.master {
    color: #9d48e0;
  }

  &.grandmaster {
    color: #cd4545;
  }

  &.challenger {
    color: #d4af37;
  }
}
.league_points {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 0px;
}

.win-loss {
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;

  .wins {
    color: #149c3a;
  }

  .loss {
    color: red;
  }
  .top-4-rate {
    font-size: 15px;
    font-weight: normal;
    margin-left: 10px;
  }
}

.position-place {

  width: 100%;
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: start;

  background-color: #080a352f;
  border: 1px solid #dddddd44;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 8px;
  margin-right: 8px;

  &.p1 {
    background-color: #d4af37;
    border-color: #d4af37;;
    color: black;
  }

  &.p2 {
    background-color: #C0C0C0;
    border-color: #C0C0C0;
    color: black;
  }

  &.p3 {
    background-color: #CD7F32;
    border-color: #CD7F32;
    color: black;
  }

  &.p4 {
    background-color: #17c9ff;
    border-color: #17c9ff;
    color: #111;
  }

  .position-number {
    width: 40%;
    display: flex;

    align-items: center;
    justify-content: center;

    font-size: 18px;
  }

  .position-count {
    flex: 1;
    text-align: right;
    padding-right: 5px;
    font-size: 20px;
    font-weight: bold;
  }
}

li.champion-tft {
  display: flex;
  align-items: center;
}

.img-champion {
  border: 2px solid #333;

  &.tier1 {
    border-color: #888;
  }
  &.tier2 {
    border-color: #00AE0A;
  }
  &.tier3 {
    border-color:#0093FF;
  }
  &.tier4 {
    border-color:#E537A2;
  }
  &.tier5 {
    border-color: #EB9C00;
  }
}

li.stat-highlight {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 5px;

  .image-wrapper {
    height: 50px;
    width: 50px;
    margin-right: 10px;
    border: 2px solid #444;
    position: relative;

    .cost {
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: 1;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 3px;
      font-size: 10px;
    }

    .image {
      width: 100%;
    }

    &.tft-trait {
      border-radius: 50%;
      padding: 5px;
      overflow: hidden;
    }

    &.tft-champion {
      border: 2px solid #333;
      overflow: hidden;
    
      &.tier1 {
        border-color: #888;

        .cost {
          background-color: #888;
        }
      }
      &.tier2 {
        border-color: #00AE0A;
        .cost {
          background-color: #00AE0A;
          color: black;
        }
      }
      &.tier3 {
        border-color:#0093FF;
        .cost {
          background-color: #0093FF;
        }
      }
      &.tier4 {
        border-color:#E537A2;
        .cost {
          background-color: #E537A2;
          color: black;
        }
      }
      &.tier5 {
        border-color: #EB9C00;
        .cost {
          background-color: #EB9C00;
          color: black;
        }
      }
    }
  }

  .label {
    flex: 1;
    min-width: 150px;

    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .stat {
    max-width: 130px;
    min-width: 70px;
    width: 12%;

    display: flex;
    align-items: end;
    justify-content: end;
    flex-direction: column;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    padding: 10px;

    .stat-title {
      margin-top: 4px;
      font-size: 10px;
      font-weight: normal;
      color: var(--primary);
      text-transform: uppercase;
    }
  }

}

.show-all {
  position: absolute;
  right: 14px;
  top: 14px;


  color: var(--primary);
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
}

.champion-top-4-sub {
  position: absolute;
  bottom: 10px;
  right: 80px;
  font-size: 10px;
}

.champion-avg {
  position: absolute;
  top: 10px;
  right: 160px;
  font-size: 18px;
  color: cyan;
}

.champion-avg-sub {
  position: absolute;
  bottom: 10px;
  right: 160px;
  font-size: 10px;
}

.card .card-header {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  background-color: #111;
  padding-top: 11px;
}
.search-player {
  border: 0px solid transparent!important;
  box-shadow: unset!important; 
  background-color: #333!important;
  border-radius: 0px!important;
}

.clear-search {
  height: 36px;
  display: flex;
  padding: 5px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: black;
  cursor: pointer;
}

.player-name {
  font-size: 25px;
  font-weight: bold;
}

.preview-data {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.preview-data-title {
  font-size: 12px;
  text-align: center;
}

.match-champion {

  img {
    border: 1px solid #eee;
    
    &.tier1 {
      border-color: #00AE0A;
    }
    &.tier2 {
      border-color:#E537A2;
    }
    &.tier3 {
      border-color: #EB9C00;
    }
  }

}