.game-info {
  font-weight: bold;
  margin-bottom: 3px;
  border-bottom: 1px solid #dddddd88;
}

.game-info-value {
  color: var(--primary);
  margin-bottom: 0px;
}

#tft-match {
  overflow: auto;
}

.tft-match-player {
  display: flex;
  align-items: stretch;
  justify-content: start;
  border: 1px solid #dddddd33;
  margin-bottom: 5px;
  background-color: #111;
  flex: 1;

  .tft-match-placement {
    min-width: 70px;
    aspect-ratio: 1/1;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #222;

    font-weight: bold;
    font-size: 30px;
    font-family: 'Poppins';
    border-right: 1px solid #dddddd33;

    &.p1 {
      background-color: #d4af37;
      border-color: #d4af37;;
      color: black;
    }
  
    &.p2 {
      background-color: #C0C0C0;
      border-color: #C0C0C0;
      color: black;
    }
  
    &.p3 {
      background-color: #CD7F32;
      border-color: #CD7F32;
      color: black;
    }
  
    &.p4 {
      background-color: #576bce;
      border-color: #576bce;
    }
  }

  .tft-match-name {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 5px;
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 20px;
    
    width: 200px;
    font-size: 20px;
    line-height: 1;
  
    &.puuid {
      font-size: 14px;
    }
  }

  .tft-match-units {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: start;

    min-width: 600px;
    max-width: 650px;

    .tft-match-champion {
      height: 50px;
      width: 50px;
      margin-right: 15px;
    
      position: relative;
      border: 1px solid #dddddd66;


      &.rarity2 {
        border-color: #00AE0A;
      }
      &.rarity3 {
        border-color:#0093FF;
      }
      &.rarity4 {
        border-color:#E537A2;
      }

      &.rarity5 {
        color: chartreuse;
      }

      &.rarity6 {
        border-color: #EB9C00;
      }

      .stars {
        position: absolute;
        top: -5px;
        left: 0px;
        width: 100%;
        height: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        
        .star {
          height: 15px;
          filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
          fill: var(--primary);

          &.lvl1 {
            fill: #E537A2;
          }
          &.lvl3 {
            fill: #EB9C00;
          }
        }
      }

      &.remove-items {
        margin-right: 5px;
      }
      .items {
        position: absolute;
        top: -7px;
        right: -14px;
        width: 20px;
        height: 60px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 1px;

        .item {
          height: 18px;
          border: 1px solid #dddddd66;
          margin-bottom: 1px;
        }
      }
    }
  }

  .tft-match-space {
    flex: 1;
  }     
  
  .tft-match-traits {
    flex: 1;
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;

    min-width: 450px;
    max-width: 450px;

    padding-top: 3px;
    padding-bottom: 3px;

    .trait {
      border: 1px solid #dddddd44;
      padding: 10px;
      padding-top: 0px;
      padding-bottom: 2px;
      border-radius: 20px;
      margin-bottom: 3px;
      margin-right: 5px;
      font-size: 12px;

      .value {
        font-size: 14px;
        font-weight: bold;
        margin-left: 5px;
      }

      &.bronze {
        border-color: #CD7F32;
        color: #CD7F32;
      }

      &.silber {
        border-color: #C0C0C0;
        color: #C0C0C0;
      }

      &.gold {
        border-color: #D4AF37;
        color: #D4AF37;
      }

      &.chromatic {
        border-color: #576bce;
        color: #576bce;
      }

      .trait-img {
        height: 15px;
        margin-top: -3px;
        margin-right: 3px;
        margin-left: -2px;
      }
    }
  }

  .tft-match-augments {
    min-width: 200px;

    display: flex;
    align-items: center;
    justify-content: end;

    .augment {
      height: 50px;
      width: 50px;
      margin-left: 10px;

      img {
        width: 100%;
        border: 1px solid #dddddd66;
      }
    }
  }

  .tft-match-stats {
    flex: 1;
    min-width: 220px;
    max-width: 220px;

    display: flex;
    align-items: center;
    justify-content: end;

    .tft-stat {

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      padding-right: 15px;
      padding-left: 15px;
      line-height: 1;

      font-size: 20px;
      font-weight: bold;
      .tft-stat-title {
        font-size: 10px;
        margin-top: 5px;
      }
    }
  }

}